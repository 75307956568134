import React from 'react'

//import components
import Project from '../components/Project'
import Padding from '../components/Padding'
import FullWidthImage from '../components/FullWidthImage'
import ImageWithParagraph from '../components/ImageWithParagraph'
import Image from '../components/Image'
import Icons from '../components/Icons'
import NextSections from '../components/NextSections'

//import images
import full from '../images/mens-comfort-decoded-merch/comfort-full.jpg'
import backgrond from '../images/mens-comfort-decoded-merch/comfort-background.jpg'
import icon1 from '../images/mens-comfort-decoded-merch/comfort-icon-1.png'
import icon2 from '../images/mens-comfort-decoded-merch/comfort-icon-2.png'
import icon3 from '../images/mens-comfort-decoded-merch/comfort-icon-3.png'
import icon4 from '../images/mens-comfort-decoded-merch/comfort-icon-4.png'
import supporting1 from '../images/mens-comfort-decoded-merch/comfort-supporting-1.jpg'
import supporting2 from '../images/mens-comfort-decoded-merch/comfort-supporting-2.jpg'

export default () => (
    <Project
        title={`men's comfort decoded merchandise`}
            date={'2018'}
            position={'Graphic Designer'}
            employer={'Boscovs Department Store'}
            brief={[
                `The Men’s Department needed an update as well as a way to roll out a new sizing campaign; Comfort Decoded. I was tasked to come up with a look that not only explained the new sizing labels but could also be adapted and used throughout the department.`,
                `With direction from one of the Creative Managers, I developed the sizing icons and from there, was able to create a color scheme, pattern, and style that was fresh, yet still masculine. The result was described as "fresh and engaging" by the Director of Visual Merchandising.`,
                `The branding was incorporated into overheads and free-standing signs that could be placed within different sections of the Men’s Department.`
            ]}
            skills={[
                'Illustration & Layout Design',
                'Photo Manipulation',
                'Large Format Printing',
                'Brand Development'
            ]}
    >

        <FullWidthImage
            src={full}
            alt={'Mockup on gray background'}
        />

        <Icons 
            icons={[{
                src: icon1,
                text: 'Stretchable Fabric',
                alt: 'Arrow icon'
            },
            {
                src: icon2,
                text: 'Flexible Collar',
                alt: 'Arrow icon'
            },
            {
                src: icon3,
                text: 'Tekfit',
                alt: 'Arrow icon'
            },
            {
                src: icon4,
                text: 'Flexible Arm',
                alt: 'Arrow icon'
            }]}
            title={'SIZE ICONS'}
            text={'These icons were introduced in the visual displays and used as identifiable symbols on the tags of the clothes.'}
        />

        <ImageWithParagraph 
            background={backgrond}
            title={'BRANDING'}
            text={`The pattern used for the men's visual merchandising was used to create cohesion throughout the pieces. It consists of geometric forms, which create a masculine feel without overpowering the merchandise.`}
        />

        <Padding />

        <Image
            src={supporting1}
            alt={'Department store mockup'}
            size={800}
        />

        <Padding />

        <Image
            src={supporting2}
            alt={'Department store mockup'}
            size={800}
        />

        <Padding />

        <NextSections
            previous={'alloy-chemical-presentation'}
            next={'petite-week-merchandise'}
        />
    </Project>
)