import React from 'react'

//import components
import Line from './Line'
import Padding from './Padding'

//import styles
import styles from './Icons.module.css'

export default ({icons, title, text}) => (
    <div className={styles.container}>
        <Padding />
        <div className={styles.content}>
            <Line />
            <h3>{title}</h3>
            <p>{text}</p>
        </div>
        <div className={styles.flex}>
            {icons.map((icon) => 
                <div key={icon.text}>
                    <img src={icon.src} alt={icon.alt} />
                    <h3>{icon.text}</h3>
                </div>
            )}
        </div>
        <Padding />
    </div>
)